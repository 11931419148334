.registration-form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 4em 0;
  gap: 1em;
}

.form-row {
  display: flex;
  gap: 1em;
  width: 100%;
  flex-wrap: wrap;
}

.form-row .form-input-container {
  flex: 1 1 200px;
}

.form-input-container {
  display: flex;
  flex-direction: column;
  gap: .3em;
  width: 100%;
}


.input-labels {
  text-align: left;
  font-weight: 500;
  font-size: 1.1em;
  color: #363C4F;
}

.registration-inputs {
  border: 2px solid #E7ECF2;
  border-radius: 4px;
  padding: 1em;
  background-color: #fff;
  width: 100%;
  outline: none;
}

.registration-inputs:focus {
  border: 2px solid #407BFF;
  background-color: rgb(228, 223, 223);
}

.registration-inputs:not(:focus) {
  background-color: #fff;
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #fff;
}

.registration-inputs::placeholder {
  color: #8a8d95;
}

.mobile-phone-wrapper .registration-inputs {
  border: none;
}

.input-container {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 2px solid #E7ECF2;
  border-radius: 4px;
  padding: 0 .5em;
}

.input-container .registration-inputs:focus {
  border: none;
}

.input-container:focus-within {
  border: 2px solid #407BFF;
}

.input-container img {
  width: 40px;
  height: 20px;
}

.input-container select {
  border: none;
}

.error-input {
  border: 2px solid #FF725E;
}

.phone-row {
  flex-wrap: nowrap;
  display: grid;
  grid-template-columns: .7fr 1.3fr;
}

.registration-accept-container {
  display: flex;
  align-items: center;
  gap: 1em;
  margin: 2em 0 0 0;
}

.registration-accept-container #accept-terms {
  color: #407BFF;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  width: 100%;
}

.btn-registration {
  width: 100%;
  font-weight: 500;
  font-size: 1em;
  letter-spacing: 2px;
  border: none;
  border-radius: 8px;
  background-color: #2A38A4;
  color: #fff;
  padding: 1em 2em;
}

.btn-registration:hover {
  opacity: .8;
}

/* Checkbox */
.registration-accept-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.registration-accept-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border-radius: 4px;
  border: 2px solid #5470FF;
}

.registration-accept-container input:checked ~ .checkmark {
  background-color: #5470FF;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.registration-accept-container input:checked ~ .checkmark:after {
  display: block;
}

.registration-accept-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.registration-accept-text {
  font-size: 1.1em;
}

.registration-accept-text a {
  color: #407BFF;
  font-weight: 600;
  text-decoration: none;
}

@media only screen and (max-width: 985px) {
  .phone-row {
    flex-wrap: nowrap;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}