* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  height: 100%;
  font-family: 'DM Sans', sans-serif;
}

html, body, #app, #app>div, #root {
  font-size: 100%;
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: 'DM Sans', sans-serif;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

hr {
  width: 100%;
}

button {
  cursor: pointer;
}

textarea:focus, 
input:focus{
  outline: none;
}

.text-header { color: #2A38A4; }
.text-header-medium { color: #407BFF; }
.text-medium { color: #808080; }
.text-medium-dark { color: #5E6372 }
.text-dark { color: #434141; }
.text-darker { color: #292D32; }
.text-warning { color: #FF725E; }
.text-success { color: #3c9e43 }

.error-msg {
  text-align: left;
  font-size: .9em;
  color: #FF725E;
}

.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #2A38A4 #C4D3E8;
}

.scrollbar::-webkit-scrollbar {
  width: .7em;
  height: .7em;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: #C4D3E8;
  border-radius: 20px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #2A38A4;
  border-radius: 20px;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #1C256D;
}