.verify-form-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.verify-form-container h2 {
  font-size: 2.5em;
}

.verify-form-container p {
  font-size: 1.13em;
}

.verify-form-container span {
  font-weight: 600;
}

.verify-form-container .form-input-container {
  margin: 1em 0;
}

.verify-form-container .link-paragraph {
  text-align: center;
  font-size: 1.13em;
  margin: .5em 0 0 0;
}

.verify-form-container .link-paragraph span {
  cursor: pointer;
}