.form-domain-input-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}

.form-domain-row-container {
  display: flex;
  gap: .5em 2em;
}

.form-domain-row-container button {
  background: transparent;
  border: none;
  padding: 0;
  font-weight: 600;
  color: #407BFF;
}

.form-domain-row-container button:hover {
  opacity: .7;
}

.form-domain-input-container span {
  font-style: italic;
  color: #9A9DA7;
}

.form-domain-input-container input {
  border: none;
  padding: .5em;
  width: 120px;
  border-radius: 4px;
}

.form-domain-input-container input.error-input {
  border: 1px solid #FF725E;
}

.form-domain-input-container input:focus {
  color: #363C4F;
  border: 1px solid #5470FF;
}

.form-domain-status {
  display: flex;
  align-items: center;
  gap: 5px;
}

.form-domain-status span {
  font-size: .9em;
}

.domain-status-success img {
  width: 1em;
  height: 1em;
}

.domain-status-success span { color: #0CBC8B; }
.domain-status-fail span { color: #FF725E; }

.domain-status-fetching .spinner {
  width: 2.5em;
  height: 2.5em;
}

.domain-status-fetching span { 
  color: #2a38a4;
  font-style: italic;
}


@media only screen and (max-width: 1050px) {
  .form-domain-row-container {
    flex-direction: column;
  }
}

@media only screen and (max-width: 500px) {
  .form-domain-input-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}