.buttonsection{
    text-align: right;
    height:50px;
    color:white;
    font-size: 14px;
    background-color:rgba(64, 123, 255, 1);
    width: 200px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    cursor: pointer;
}

.spans{
    color:rgba(64, 123, 255, 1);
    cursor: pointer;
}