.home-body {
  width: 100%;
  height: 100%;
}

.home-main {
  display: flex;
  width: 100%;
  min-height: 100%;
  background-color: #2a38a4;
}

.registration-left {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.home-left-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10em;
  padding: 2em 0;
}

.registration-left img:first-child {
  width: 60%;
}

.registration-left img:nth-child(2) {
  width: 90%;
  object-fit: cover;
}

.home-right {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em 0;
  background-color: #fff;
}

.home-right-container {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  background-color: #fff;
}

.home-right-container h2 {
  word-spacing: 5px;
  font-size: 3em;
  font-weight: 700;
  margin: 1em 0 0 0;
}

.home-right-container p {
  font-size: 1.2em;
}

.registration-button {
  width: 100%;
  font-weight: 500;
  font-size: 1em;
  letter-spacing: 2px;
  border: none;
  border-radius: 8px;
  background-color: #2A38A4;
  color: #fff;
  padding: 1em 2em;
  margin: 2em 0 0 0;
}

.registration-link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1em;
  letter-spacing: 2px;
  margin: 2em 0 0 0;
  border-radius: 8px;
  background-color: #2A38A4;
  text-align: center;
  cursor: pointer;
}

.registration-link a {
  text-decoration: none;
  width: 100%;
  color: #fff;
  display: block;
  padding: 1em 2em;
}

/* All Media Queries */
@media only screen and (max-width: 900px) {
  .home-right-container {
    width: 80%;
  }
}

@media only screen and (max-width: 800px) {
  .registration-left {
    display: none;
  }

  .home-main {
    justify-content: center;
    align-items: center;
  }

  .home-right {
    min-height: 80%;
    width: 80%;
    border-radius: 10px;
    margin: 2em;
  }
}

@media only screen and (max-width: 600px) { 
  .home-right {
    width: 90%;
  }
}